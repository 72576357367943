import React from 'react'
import AboutUs from './AboutUs'
import Education from './Education'
import Gallery from './Gallery'
import HeroSection from './HeroSection'
import Carousel1 from './PhotoGalary'
import Events from './Event'

export default function 
() {
  return (
    <div>
      <HeroSection />
      <AboutUs />
      <Education />
      <Gallery />
      {/* <Events/> */}
   
      
    </div>
  )
}
